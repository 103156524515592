var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"addPerson__wrap"},[_c('el-header',{staticClass:"shadow page-header__wrap"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"backBtn",on:{"click":_vm.handleReturn}},[_c('el-image',{staticClass:"back-img",attrs:{"src":require('@/assets/img/back.png')}}),_c('span',[_vm._v("返回")])],1),_c('div',{staticClass:"title"},[_c('span',{staticClass:"site"},[_vm._v("当前位置：")]),_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),_c('span',[_vm._v(" > ")]),_vm._v(" 下单明细 ")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"itemTime"},[_vm._v("订单明细")]),_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","font-size":"14px"}},[_c('div',[_c('span',{staticStyle:{"color":"#898989"}},[_vm._v("供应商/采购员：")]),_vm._v(_vm._s(_vm.currRow.bind_name || '--'))]),_c('div',{staticStyle:{"margin-left":"50px"}},[_c('span',{staticStyle:{"color":"#898989"}},[_vm._v("标准名称：")]),_vm._v(_vm._s(_vm.currRow.good_name || '--'))])]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.table.data,"row-key":function (row) { return row.id; },"border":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"property":"norm_name","label":"商品名称","width":"150"}}),_c('el-table-column',{attrs:{"property":"store_name","label":"数据来源","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!!row.store_name)?_c('span',[_vm._v(_vm._s(row.store_name))]):_c('span',[_vm._v(_vm._s(row.source == 1 ? '销售订单' : '平台自采'))])]}}])}),_c('el-table-column',{attrs:{"property":"order_no","label":"订单编号","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showDetailOrder(row)}}},[_vm._v(_vm._s(row.order_no))])]}}])}),(_vm.sleDate.status == 1)?_c('el-table-column',{attrs:{"prop":"supplier_name","label":"供应商/采购员","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-popover',{ref:("popoverDom+" + $index),attrs:{"placement":"bottom","width":"300","trigger":"click"}},[_c('div',[_vm._v("供应商/采购员")]),_c('el-select',{staticStyle:{"width":"100%","margin":"10px 0"},attrs:{"filterable":"","value-key":"id"},on:{"focus":_vm.getSupplier},model:{value:(_vm.person_obj),callback:function ($$v) {_vm.person_obj=$$v},expression:"person_obj"}},_vm._l((_vm.supplierArr),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.name))]),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s(item.type == 1 ? '供应商' : '采购员'))])])}),1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleRemark(row,$index)}}},[_vm._v("确定")])],1),_c('el-button',{staticStyle:{"color":"#409eff","width":"98px","text-align":"left"},attrs:{"slot":"reference","type":"text"},slot:"reference"},[(!!row.supplier_name)?_c('span',{staticStyle:{"width":"98px","display":"inline-block","overflow":"hidden"}},[_vm._v(_vm._s(row.supplier_name))]):_c('i',{staticClass:"el-icon-edit"})])],1)]}}],null,false,2529002529)}):_vm._e(),_c('el-table-column',{attrs:{"property":"num","label":"下单数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.num)+_vm._s(_vm.currRow.unit_name))])]}}])}),_c('el-table-column',{attrs:{"property":"use_inventory","label":"使用库存"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.use_inventory))]),(row.use_inventory > 0)?_c('span',[_vm._v(_vm._s(row.unit_name))]):_vm._e()]}}])}),(_vm.currRow.selStatus == 3 || (_vm.currRow.selStatus ==1 && !!_vm.currRow.is_loss))?_c('el-table-column',{attrs:{"property":"loss_num","label":"计算损耗数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.loss_num))])]}}],null,false,31566734)}):_vm._e(),_c('el-table-column',{attrs:{"property":"purchase_num","label":"需采购数量","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.sleDate.status == 1)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},on:{"input":function($event){row.purchase_num=row.purchase_num.match(/\d+(\.\d{0,2})?/) ? row.purchase_num.match(/\d+(\.\d{0,2})?/)[0] : ''}},model:{value:(row.purchase_num),callback:function ($$v) {_vm.$set(row, "purchase_num", $$v)},expression:"row.purchase_num"}}),_c('span',{staticStyle:{"margin-left":"5px","width":"60px"}},[_vm._v(_vm._s(row.unit_name))])],1):_vm._e(),(_vm.sleDate.status == 3 && !!row.purchase_num)?_c('span',[_vm._v(_vm._s(row.purchase_num)+_vm._s(row.unit_name))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"property":"create_time","label":"创建时间","width":"140"}}),_c('el-table-column',{attrs:{"property":"remark","label":"备注","width":"160"}}),_c('el-table-column',{attrs:{"property":"update_time","label":"更新时间","width":"160"}})],1),(_vm.table.total > 10)?_c('div',{staticClass:"pageWrap"},[_c('el-pagination',{attrs:{"current-page":_vm.table.params.page,"page-sizes":[10, 20, 50, 100],"page-size":_vm.table.params.count,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1),(_vm.sleDate.status == 1)?_c('div',{staticClass:"footerWrap"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleCancel}},[_vm._v("取消采购")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("保存")])],1):_vm._e()])],1),_c('OrderDetail',{ref:"orderdetail"}),_c('PurchaseDetail',{ref:"purchasedetail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }